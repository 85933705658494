// vue
import { ref, watch, reactive, computed, onMounted } from 'vue';

// store
import { useGl } from '@store/ts/gl';
import { useRefs } from '@store/ts/refs';
import { useBots } from '@store/bots';

// i18n
import { useI18n } from 'vue-i18n';

// services
import BotsService from '@services/bots';

// naive-ui
import { useMessage } from 'naive-ui';

// naive-ui
import { useNotification } from 'naive-ui';

export default function (props, { emit }) {
    // store
    const gl = useGl();
    const bots = useBots();
    const refs = useRefs();

    // i18n
    const { t } = useI18n();

    // naive-ui
    const message = useMessage();

    // vars
    const show = ref(false);
    const dataForm = bots.dataForm;
    const whiteFiltersList = [
        'stop_loss:last_rate:change',
        'stop_loss:rate:change',
        'stop_loss:exit:bool',
        'stop_loss:signal:bool',
        'stop_loss:timeout:time',
    ];

    const howToSetStopLossLinks = {
        'ru': 'https://desk.revenuebot.io/ru/%D0%B1%D0%B0%D0%B7%D0%B0-%D0%B7%D0%BD%D0%B0%D0%BD%D0%B8%D0%B9/article/%D0%B8%D1%81%D0%BF%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D0%B5-stop-loss-%D0%B2-revenuebot',
        'en': 'https://desk.revenuebot.io/en/knowledgebase/article/using-stop-loss-in-revenuebot',
        'es': 'https://desk.revenuebot.io/es/base-de-datos/article/uso-de-stop-loss-en-revenuebot',
    };

    const getHTMLid = id => {
        let result = id;
        if (result && result != -1) {
            [' ', ':', '-'].forEach(z => {
                result = result.split(z).join('_');
            });
        }
        
        return result;
    };

    watch(() => Object.keys(bots.innerForms).length, v => {
        if (v > 0) {
            const firstKey = Object.keys(bots.innerForms)[0];

            if (whiteFiltersList.includes(firstKey)) {
                show.value = true;

                setTimeout(() => {
                    const selector = getHTMLid(firstKey);
                    const el = document.querySelector(`#${selector}`);
                    
                    if (el)
                        el.scrollIntoView({ block: gl.isMobile ? 'start' : 'center', behavior: 'smooth' });
                }, 1000);
            }
        }
    });

    const startFiltersStopLossOptions = computed(() => {
        return bots.startFiltersStopLoss.map(el => ({
            label: el.title,
            value: el.id,
            ...el,
        }));
    });

    const localStartFilters = computed(() => {
        const result = [];

        dataForm.start_filters.forEach((el, index) => {
            result.push({
                ...el,
                hasFieldId: el?.hasFieldId || false,
                index,
                help: computed(() => el.changed ? getHelp(el?.hasFieldId ? el.parent_id : el.id) : getHelp(getId(el.id))),
                fields: computed(() => el.changed ? getFields(el?.hasFieldId ? el.parent_id : el.id) : getFields(getId(el.id))),
            });
        });

        return result.filter(el => hasInWhiteList(el.hasFieldId ? el.parent_id : el.id) || el?.type == 'stop-loss');
    });

    watch(() => dataForm.settings.simulate, v => {
        ['stop_loss:timeout:time', 'stop_loss:signal:bool'].forEach(filter => {
            const index = hasFilter(filter);
            if (~index) {
                dataForm.start_filters.splice(index, 1);
            }
        });
    });

    const getId = filter_id => {
        if (!filter_id || filter_id == -1) return;

        const draftList = ['bb_l', 'bb_u'];

        return filter_id.split(' ').length > 1
            ? filter_id.split(' ')[0] + ':' + filter_id.split(' ')[1].split(':')[1]
            : filter_id.split(':')[0] + ':' + (draftList.includes(filter_id.split(':')[0]) ? 'stop_loss:' : '') + filter_id.split(':')[2];
    };

    const getHelp = filter_id => {
        const el = bots.startFiltersStopLoss.find(({ id }) => id == filter_id);

        return el
            ? el.help
            : '';
    };

    const getFields = parent_id => {
        const el = bots.startFiltersStopLoss.find(({ id }) => id == parent_id);

        return el
            ? el.fields
            : [];
    };

    const hasFilter = id => {
        return dataForm.start_filters.findIndex(el => id == el.id);
    };

    const hasInWhiteList = id => {
        return !!~bots.startFiltersStopLossValues.findIndex(el => el == id);
    };

    const setMainSelect = ($event, filter) => {
        if ($event == dataForm.start_filters[filter.index][filter.hasFieldId ? 'parent_id' : 'id'])
            return;

        const localId = $event;

        const localFilter = bots.startFiltersStopLoss.find(({ id }) => id == localId);
                
        const fieldId = localFilter.fields.findIndex(({ name }) => name == 'id');

        // set default value
        localFilter.fields.forEach(field => {
            if (field?.value != undefined && field?.value != null) {
                dataForm.start_filters[filter.index][field.name] = field.value;
            } else if (field?.min != undefined && field?.min != null) {
                dataForm.start_filters[filter.index][field.name] = field.min;
            }
        });

        if (~fieldId) {
            dataForm.start_filters[filter.index].parent_id = $event;
            dataForm.start_filters[filter.index].id =
            localFilter.fields[fieldId].input_type == 'hidden'
                ? localFilter.fields[fieldId].value
                : -1;
        } else {
            dataForm.start_filters[filter.index].id = $event;
            dataForm.start_filters[filter.index].parent_id = -1;
        }

        dataForm.start_filters[filter.index].changed = true;
        dataForm.start_filters[filter.index].hasFieldId = !!~fieldId;
    };

    const onAddNewStartFilter = () => {
        dataForm.start_filters.push({
            id: -1,
            id_op: -1,
            value: '',
            parent_id: -1,
            changed: false,
            _formPath: `sf_${dataForm.start_filters.length + 1}`,
            type: 'stop-loss' });
    };

    const getOptions = options => {
        if (Array.isArray(options)) {
            return options.map(el => ({
                label: el.title,
                value: el.id,
                ...el,
            }));
        }
    };

    const triggerFilter = id => {
        const localId = hasFilter(id);

        if(~localId) {
            dataForm.start_filters.splice(localId, 1);
        } else {
            if (['stop_loss:exit:bool', 'stop_loss:signal:bool'].includes(id)) {
                dataForm.start_filters.push(
                    {
                        id,
                        id_op: '==',
                        value: true,
                    });
            } else {
                dataForm.start_filters.push(
                    {
                        id,
                        id_op: '==',
                        value: null,
                    });
            }
        }
    };

    const onDeleteStartFilter = $event => {
        dataForm.start_filters.splice($event, 1);
        // if (dataForm.start_filters.length === 0)
        //     dataForm.start_filters = [{ id: -1, id_op: -1, value: '' }];
    };

    const updateStartFilters = $event => {
        dataForm.start_filters[$event.i][$event.key] = $event.value;
    };

    const startFilterHasPair = (id, i) => {
        return !!bots.startFilters.find(el => el.id === id);
    };

    const updateInputNumber = $event => {
        console.log('$event', $event);
        // dataForm.start_filters[filter.index][field.name] = $event 
    };

    onMounted(() => {
        if (Array.isArray(dataForm.start_filters)) {
            dataForm.start_filters.forEach(el => {
                if (hasInWhiteList(el.id)) {
                    el.parent_id = getId(el.id);
                    el.changed = false;
                }
            });
        }
    });

    return {
        bots,
        show,
        refs,
        dataForm,
        localStartFilters,
        howToSetStopLossLinks,
        startFiltersStopLossOptions,
        getHTMLid,
        hasFilter,
        getOptions,
        setMainSelect,
        triggerFilter,
        updateInputNumber,
        startFilterHasPair,
        updateStartFilters,
        onAddNewStartFilter,
        onDeleteStartFilter,
    };
}