// vue
import { ref, watch, reactive, computed, onMounted } from 'vue';

// store
import { useGl } from '@store/ts/gl';
import { useRefs } from '@store/ts/refs';
import { useBots } from '@store/bots';

// i18n
import { useI18n } from 'vue-i18n';

// services
import BotsService from '@services/bots';

// naive-ui
import { useNotification } from 'naive-ui';

export default function (props, { emit }) {
    // store
    const gl = useGl();
    const bots = useBots();
    const refs = useRefs();

    // i18n
    const { t } = useI18n();

    // ui
    const notification = useNotification();

    // vars
    const { _ } = window;
    const filterId = ref();
    const showCreateNewPreset = ref(false);
    const presetFiltersList = ref([]);
    const dataForm = bots.dataForm;
    const show = ref(gl.isMobile ? false : dataForm.start_filters_enabled);
    const loading = ref(false);

    const blackList = [
        'start_time:check:time',
        'completed_cycles:check:count',
        'simulate_start_time:check:time',
        'algo_switch:last_rate:change',
        'algo_switch:rate:change',
        'algo_switch:time:change',
        'algo_switch:time_fix:change',
        'algo_switch:not_add_depo:bool',
        'cycle_up:check:time',
        'stop_loss:last_rate:change',
        'stop_loss:rate:change',
        'stop_loss:exit:bool',
        'stop_loss:signal:bool',
        'stop_loss:timeout:time',
        ...bots.startFiltersStopLossValues,
    ];

    watch(showCreateNewPreset, v => {
        if (!v) {
            newPresetModel.title.value = '';
            newPresetModel.description.value = '';
        }
    });

    const newPresetModel = reactive({
        title: {
            value: '',
            title: bots.localization['bot_auto_switch_type_volatility_analyzer_save_preset_name_f'],
            placeholder: bots.localization['bot_auto_switch_type_volatility_analyzer_save_preset_name_f'],
            info: undefined,
            status: undefined,
            msg: undefined,
        },
        description: {
            value: '',
            title: bots.localization['bot_auto_switch_type_volatility_analyzer_save_preset_description_i'],
            placeholder: bots.localization['bot_auto_switch_type_volatility_analyzer_save_preset_description_i'],
            info: undefined,
            status: undefined,
            msg: undefined,
        },
        algo: {
            value: computed(() => _.get(dataForm, 'algo.value')),
        },
        start_filters: {
            value: computed(() => dataForm.start_filters.map(filter => {
                const result = Object.assign({}, filter);

                delete result.changed;
                delete result.parent_id;
                delete result.hasFieldId;

                return result;
            }).filter(({ id }) => !hasInBlackList(id))),
        },
    });

    const startFiltersOptions = computed(() => bots.startFilters.map(el => ({
        label: el.title,
        value: el.id,
        ...el,
    })));

    const startFiltersOptOptions = computed(() => bots.startFilterOpts.map(el => ({
        label: el.title,
        value: el.id,
        ...el,
    })));

    const startFiltersIndOptions = computed(() => bots.startFiltersInd.map(el => ({
        label: el.title,
        value: el.id,
        ...el,
    })));

    const whatAreBotStartFiltersLink = {
        'ru': 'https://desk.revenuebot.io/ru/%d0%b1%d0%b0%d0%b7%d0%b0-%d0%b7%d0%bd%d0%b0%d0%bd%d0%b8%d0%b9/article/%d1%87%d1%82%d0%be-%d1%82%d0%b0%d0%ba%d0%be%d0%b5-%d1%84%d0%b8%d0%bb%d1%8c%d1%82%d1%80%d1%8b-%d1%81%d1%82%d0%b0%d1%80%d1%82%d0%b0-%d0%b1%d0%be%d1%82%d0%b0',
        'en': 'https://desk.revenuebot.io/en/knowledgebase/article/what-are-bot-start-filters',
        'es': 'https://desk.revenuebot.io/es/base-de-datos/article/%C2%BFqu%C3%A9-son-los-filtros-de-inicio-de-bots',
    };

    const localStartFilters = computed(() => {
        const result = [];

        dataForm.start_filters.forEach((el, index) => {
            result.push({
                ...el,
                hasFieldId: el?.hasFieldId || false,
                index,
                help: computed(() => el.changed ? getHelp(el?.hasFieldId ? el.parent_id : el.id) : getHelp(getId(el.id))),
                fields: computed(() => el.changed ? getFields(el?.hasFieldId ? el.parent_id : el.id) : getFields(getId(el.id))),
            });
        });
        
        return result.filter(el => {
            return !hasInBlackList(el.hasFieldId ? el.parent_id : el.id) && el?.type !== 'stop-loss';
        });
    });

    const getHTMLid = id => {
        let result = id;
        if (result && result != -1) {
            [' ', ':', '-'].forEach(z => {
                result = result.split(z).join('_');
            });
        }
        
        return result;
    };

    watch(() => Object.keys(bots.innerForms).length, v => {
        if (v > 0) {
            const firstKey = Object.keys(bots.innerForms)[0];

            if (!blackList.includes(firstKey)) {
                show.value = true;

                setTimeout(() => {
                    const selector = getHTMLid(firstKey);
                    const el = document.querySelector(`#${selector}`);
                    
                    if (el)
                        el.scrollIntoView({ block: gl.isMobile ? 'start' : 'center', behavior: 'smooth' });
                }, 1000);
            }
        }
    });

    const setMainSelect = ($event, filter) => {
        if ($event == dataForm.start_filters[filter.index][filter.hasFieldId ? 'parent_id' : 'id'])
            return;

        const localId = $event;

        const localFilter = bots.startFiltersInd.find(({ id }) => id == localId);

        // console.log('localFilter', localFilter);
                
        const fieldId = localFilter.fields.findIndex(({ name }) => name == 'id');

        // set default value
        localFilter.fields.forEach(field => {
            if (field?.value != undefined && field?.value != null) {
                dataForm.start_filters[filter.index][field.name] = field.value;
            } else if (field?.min != undefined && field?.min != null) {
                dataForm.start_filters[filter.index][field.name] = field.min;
            }
        });

        // console.log('hasFieldId', fieldId);

        if (~fieldId) {
            dataForm.start_filters[filter.index].parent_id = $event;
            dataForm.start_filters[filter.index].id =
            localFilter.fields[fieldId].input_type == 'hidden'
                ? localFilter.fields[fieldId].value
                : -1;
        } else {
            dataForm.start_filters[filter.index].id = $event;
            dataForm.start_filters[filter.index].parent_id = -1;
        }

        dataForm.start_filters[filter.index].changed = true;
        dataForm.start_filters[filter.index].hasFieldId = !!~fieldId;
    };

    const pairsRefsOptions = computed(() => {
        return props.pairsRefs.map(cur => {
            return {
                label: cur.title,
                value: cur.id,
                ...cur,
            };
        });
    });

    const filterPresetsOptions = computed(() => {
        const algo = _.get(dataForm, 'algo.value');
        
        return Array.isArray(bots.filterPresets)
            ? bots.filterPresets.map(el => ({
                label: el.name,
                value: el.id,
                ...el,
            })).filter(el => el.algo == algo)
            : [];
    });

    const onAddNewStartFilter = () => {
        dataForm.start_filters.push({ id: -1, id_op: -1, value: '', parent_id: -1, changed: false, _formPath: `sf_${dataForm.start_filters.length + 1}` });
    };

    const startFilterHasPair = (id, i) => {
        const res = !!bots.startFilters.find(el => el.id === id);

        if (!res)
            emit('update:start_filters', { i, key: 'pair',  value: null });

        return res;
    };

    const onDeleteStartFilter = $event => {
        dataForm.start_filters.splice($event, 1);
        // if (dataForm.start_filters.length === 0)
        //     dataForm.start_filters = [{ id: -1, id_op: -1, value: '' }];
    };

    const updateStartFilters = $event => {
        dataForm.start_filters[$event.i][$event.key] = $event.value;
    };

    const clearStartFilters = () => {
        dataForm.start_filters = dataForm.start_filters.filter(({ id }) => hasInBlackList(id));
    };

    const changeFilterPresets = $event => {
        clearStartFilters();

        const filter = bots.filterPresets.find(({ id }) => id == $event);
        // dataForm.start_filters = dataForm.start_filters.filter(({ id }) => !~presetFiltersList.value.findIndex(i => i == id));
        // presetFiltersList.value = [];

        if (filter) {
            filter.filters.start_filters.forEach(({ data, id_op, id, pair, value }) => {
                // presetFiltersList.value.push(id);

                if (!hasInBlackList(id)) {
                    dataForm.start_filters.push({
                        data,
                        id,
                        id_op,
                        pair,
                        value,
                        parent_id: getId(id),
                        changed: false,
                    });   
                }
            });
        }
    };

    const onCreateNewFiltersPreset = async () => {
        loading.value = true;

        try {
            const result = await BotsService.createNewFiltersPreset({
                title: newPresetModel.title.value,
                description: newPresetModel.description.value,
                algo: newPresetModel.algo.value,
                start_filters: newPresetModel.start_filters.value,
                _formPath: 'createNewFiltersPreset',
            });
            
            if (result) {
                if (!result?.data.status) {
                    if (result.data?.errors_form) {

                        for (let key in newPresetModel) {
                            const fields = result.data.errors_form.createNewFiltersPreset.fields;
                            const el = Object.keys(fields).find(el => el === key);

                            if (el) {
                                newPresetModel[key].status = 'error';
                                newPresetModel[key].msg = fields[el].msg;
                            } else {
                                newPresetModel[key].status = 'success';
                                newPresetModel[key].msg = undefined;
                            }
                        }
                    }
                } else {
                    // show messages
                    if (result?.postMessages)
                        result.postMessages.forEach(el => {
                            notification[el.success ? 'success' : 'error']({
                                content: el.msg,
                                duration: 2500,
                                keepAliveOnHover: true,
                            });
                        });

                    bots.refs.filter_presets.push({
                        name: result.data.preset.title,
                        ...result.data.preset,
                    });

                    newPresetModel.title.value = '';
                    newPresetModel.description.value = '';

                    showCreateNewPreset.value = false;
                }
            }
        } catch {
            notification.error({
                content: t('errorMessage'),
                duration: 2500,
                keepAliveOnHover: true,
            });
        };

        loading.value = false;
    };

    const getId = filter_id => {
        if (!filter_id || filter_id == -1) return;

        return filter_id.split(' ').length > 1
            ? filter_id.split(' ')[0] + ':' + filter_id.split(' ')[1].split(':')[1]
            : filter_id.split(':')[0] + ':' + filter_id.split(':')[2];
    };

    const getHelp = filter_id => {
        const el = bots.startFiltersInd.find(({ id }) => id == filter_id);

        return el
            ? el.help
            : '';
    };

    const getFields = parent_id => {
        const el = bots.startFiltersInd.find(({ id }) => id == parent_id);

        return el
            ? el.fields
            : [];
    };

    const getOptions = options => {
        if (Array.isArray(options)) {
            return options.map(el => ({
                label: el.title,
                value: el.id,
                ...el,
            }));
        }
    };

    const hasInBlackList = id => {
        return !!~blackList.findIndex(el => id == el);
    };

    onMounted(() => {
        if (Array.isArray(dataForm.start_filters)) {
            dataForm.start_filters.forEach(el => {
                if (!hasInBlackList(el.id)) {
                    el.parent_id = getId(el.id);
                    el.changed = false;
                }
            });
        }
    });

    return {
        gl,
        show,
        bots,
        refs,
        loading,
        filterId,
        dataForm,
        newPresetModel,
        pairsRefsOptions,
        localStartFilters,
        showCreateNewPreset,
        startFiltersOptions,
        filterPresetsOptions,
        startFiltersOptOptions,
        startFiltersIndOptions,
        whatAreBotStartFiltersLink,
        getOptions,
        getHTMLid,
        setMainSelect,
        hasInBlackList,
        updateStartFilters,
        startFilterHasPair,
        onAddNewStartFilter,
        onDeleteStartFilter,
        changeFilterPresets,
        onCreateNewFiltersPreset,
    };
}